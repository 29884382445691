import React, { useState } from 'react';
import Router from 'next/router';
import {
	Container, Box, Flex, Heading, Text, Icon, Button, Input, Image,
	InputGroup, InputRightElement,
} from '@chakra-ui/react';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Page from '../components/Page';

function App() {
	const [text, setText] = useState('');

	function Feature({ title, desc }) {
		return (
			<Box
				width={['calc(48% - 20px)', 'calc(30% - 20px)']}
				m="10px"
				p={[4, 10]}
				borderRadius="10px"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				shadow="xl"
				borderWidth="1px"
			>
				<Heading fontSize="xl" textAlign="center">{title}</Heading>
				<Text mt={4}>{desc}</Text>
			</Box>
		);
	}

	return (
		<Page title="星可風暴" subtitle="線上腦力激盪的最佳工具" isFull>
			<Box py={3} color="#121212">
				<Container maxW="container.lg">
					<Flex direction={['column', 'row']} alignItems="center" justifyContent="space-between" py={[5, 20]}>
						<Box pb={6}>
							<Heading as="h2" mt={[0, 10]} mb={[0, 6]} fontSize="4xl" fontWeight="bold">
								<Flex>
									線上
									<Text color="pryOrange">腦力激盪</Text>
									的
								</Flex>
								<Text>最佳工具</Text>
							</Heading>
							<Text my={3} fontSize="sm" fontWeight="bold">打破傳統思維框架，一起為題目打造出創新的想法</Text>
							<Box mt={5}>
								<InputGroup size="md">
									<Input
										pr="4.5rem"
										h="3rem"
										type="text"
										size="sm"
										fontSize="sm"
										border="2px solid"
										borderColor="pryOrange"
										borderLeftRadius="20px"
										borderRightRadius="20px"
										placeholder="輸入房間代碼"
										value={text}
										onChange={(e) => setText(e.target.value.toUpperCase())}
										onKeyDown={(e) => { if (e.key === 'Enter') Router.push(`/session?id=${text}`); }}
										position="relative"
									/>
									<InputRightElement width="4.5rem">
										<Button
											px={6}
											h="3rem"
											size="sm"
											borderLeftRadius="20px"
											borderRightRadius="20px"
											bgColor="pryOrange"
											colorScheme="orange"
											position="absolute"
											right={0}
											top={0}
											rightIcon={<Icon mr={1} as={Fa} icon={faArrowRight} />}
											onChange={(e) => setText(e.target.value.toUpperCase())}
											onClick={() => {
												if (text !== '') {
													Router.push(`/session?id=${text}`);
												}
											}}
											onKeyDown={(e) => { if (e.key === 'Enter' && text !== '') Router.push(`/session?id=${text}`); }}
										>
											加入
										</Button>
									</InputRightElement>
								</InputGroup>
							</Box>
						</Box>
						<Box w={['100%', '50%']} py={[2, 10]} px={[2, 10]} mx="auto" textAlign="center">
							<Image alt="svg" src="/index/undraw.svg" />
						</Box>
					</Flex>
					<Box my={[0, 4]}>
						<Text as="h3" textAlign="center" fontSize="xl" fontWeight="bold">協助組織創新發想、跳脫框架</Text>
						<Flex mt={[2, 10]} flexWrap="wrap" justifyContent="center">
							<Feature title="匿名發言" desc="房間匿名，每個人的發言都是好點子！" />
							<Feature title="限時激盪" desc="限時內激發無限潛能" />
							<Feature title="隨機組合" desc="自動抽選隨機組合，踩在前人肩膀上，發展前所未有的主意！" />
							<Feature title="時間掌控彈性" desc="主持人依程度延長或快轉，保留時間掌控彈性程度" />
							<Feature title="投票" desc="票選最佳組合，收斂公認最棒的共識" />
							<Feature title="推薦開放資料集" desc="結合全臺開放資料集，發想主意同時挖掘政府開放資料" />
						</Flex>
					</Box>
					<Box my={10}>
						<Text as="h3" textAlign="center" fontSize="xl" fontWeight="bold">限時腦力激盪，擦出前所未有的火花</Text>
						<Box w="100%" py={[6, 10]} px={[3, 10]} mx="auto" borderLeftRadius="20px" borderRightRadius="20px">
							<Image alt="png" src="/index/process.png" />
						</Box>
					</Box>
					<Box my={10}>
						<Text as="h3" textAlign="center" fontSize="xl" fontWeight="bold">使用經驗分享</Text>
						<Box display="flex" justifyContent="space-between">
							<Box>
								<Text fontSize="8xl" color="pryOrange">“</Text>
							</Box>
							<Box mt={['0', '50px']} p={['5px', '0px']}>
								<Heading fontSize="xl" textAlign="center">星可風暴利用遊戲化的方式，在短時間內就有許多成果，印象超級深刻！</Heading>
							</Box>
							<Box mt={['0', '50px']}>
								<Text fontSize="8xl" color="pryOrange">”</Text>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Page>
	);
}

export default App;
